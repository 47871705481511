<template>
  <va-card title="Request">
    <div style="margin-bottom: 15px;">
      <va-button
        @click="remove()"
        small
        color="danger"
        class="ma-0"
      >
        Видалити
      </va-button>
    </div>
    <div class="mb-3">
      <h2 class="display-4 mb-1">Type</h2>
      <span>{{ type_name }}</span>
    </div>

    <div class="mb-3">
      <h2 class="display-4 mb-1">Name</h2>
      <span>{{ name }}</span>
    </div>

    <div class="mb-3">
      <h2 class="display-4 mb-1">Email</h2>
      <span>{{ email }}</span>
    </div>

    <div class="mb-3">
      <h2 class="display-4 mb-1">Phone</h2>
      <span>{{ phone }}</span>
    </div>

    <div v-if="message" class="mb-3">
      <h2 class="display-4 mb-1">Message</h2>
      <span>{{ message }}</span>
    </div>

    <div class="mb-3" v-if="career_objective">
      <h2 class="display-4 mb-1">Career objective</h2>
      <span>{{ career_objective }}</span>
    </div>

    <div class="mb-3" v-if="city">
      <h2 class="display-4 mb-1">City</h2>
      <span>{{ city }}</span>
    </div>

    <div class="mb-3" v-if="additional_information">
      <h2 class="display-4 mb-1">Additional information</h2>
      <span>{{ additional_information }}</span>
    </div>
    <div v-if="hasResume" class="mb-3">
      <h2 class="display-4 mb-1">File</h2>
      <a target="_blank" :href="file" class="link mr-4">
        Download
      </a>
    </div>

    <div class="mb-3" v-if="created_at">
      <h2 class="display-4 mb-1">Created at</h2>
      <span>{{ created_at }}</span>
    </div>
  </va-card>
</template>

<script>
import * as axios from 'axios'
import Vue from 'vue'

export default {
  data () {
    return {
      type_name: '',
      name: '',
      email: '',
      phone: '',
      message: '',
      created_at: '',

      file: null,

      career_objective: '',
      city: '',
      additional_information: '',
      hasResume: false,
    }
  },
  created () {
    this.fetch()
  },
  computed: {
    locale () {
      return Vue.i18n.locale()
    },
    apiUrl () {
      return process.env.VUE_APP_AUTOSTRADA_API_URL
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
  methods: {
    remove (id = this.$attrs.id) {
      if (confirm('Дійсно видалити?')) {
        axios.delete(`${process.env.VUE_APP_AUTOSTRADA_API_URL}/${this.locale}/admin/site-request/${id}`)
          .then(response => {
            this.showToast('Success')
            this.$router.push({ name: 'autostrada-request' })
          })
          .catch(error => {
            console.log(error)
            this.showToast('Error')
          })
      }
    },
    fetch () {
      axios.get(`${process.env.VUE_APP_AUTOSTRADA_API_URL}/${this.locale}/admin/site-request/${this.$attrs.id}`)
        .then(response => {
          this.type_name = response.data.type_name
          this.name = response.data.name
          this.email = response.data.email
          this.phone = response.data.phone
          this.message = response.data.message
          this.career_objective = response.data.career_objective
          this.city = response.data.city
          this.additional_information = response.data.additional_information
          const date = new Date(response.data.created_at * 1000)
          this.created_at = date.toLocaleDateString('uk-UA') + ' ' + date.toLocaleTimeString('uk-UA')
          console.log(response.data.without_resume)
          this.hasResume = response.data.without_resume === 0 && response.data.type === 2 // type = resume
          this.file = response.data.file
        })
        .catch(error => {
          console.log(error.response)
        })
    },
  },
}
</script>

<style lang="scss">
</style>
